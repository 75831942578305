import React from 'react'
import './footer.css'

const Footer = () => {
  return (
    <footer>
      <div className='contentFooter'>
        &copy; Richardd82 content. All rights reserved
      </div>
    </footer>
  )
}

export default Footer